import {
  UilCalendarAlt,
  UilClockNine,
  UilUsdCircle,
} from '@iconscout/react-unicons';
import { parseISO } from 'date-fns';
import React from 'react';

import ExpiringInBadge from '@/components/Molecule/ExpiringInBadge';
import PayoutBadge from '@/components/Molecule/Home/PayoutBadge';
import { MySlotFragment, SlotFragment } from '@/generated/graphql';
import {
  InternalLink,
  InternalLinkProps,
  RouterPathBuilderUtil,
} from '@/hooks/useInternalRouter';
import { getOfferText } from '@/models/Offer';
import { formatSlotModel, SlotType } from '@/models/Slot';
import { SlotUtils } from '@/models/SlotDetail';
import ImageWithFallback from '@/utils/ImageWithFallback';

function FutureSlotCard(props: {
  slot: MySlotFragment | SlotFragment;
  artist?: {
    name: string;
    photo1x1: string;
  };
  variant: 'list' | 'grid';
}) {
  const { slot, artist, variant } = props;

  const isMySlot = `offer` in slot;
  const linkProps: InternalLinkProps = isMySlot
    ? {
        builder: RouterPathBuilderUtil.fromSlot(slot),
      }
    : { href: null };

  const offerText = isMySlot
    ? getOfferText(slot.ntRec?.offer ?? slot.offer)
    : null;

  switch (variant) {
    case `list`: {
      return (
        <InternalLink {...linkProps} className="contents">
          <div className="flex gap-16 overflow-hidden px-16">
            <div className="size-88 shrink-0">
              <ImageWithFallback
                key={SlotUtils.getSlotImage(slot)[0]}
                src={SlotUtils.getSlotImage(slot)[0]}
                width={88}
                height={88}
                className="rounded-12 object-cover"
              />
            </div>

            <div className="flex flex-col gap-16 overflow-hidden">
              <div className="flex flex-col gap-8 overflow-hidden">
                <div className="flex flex-col gap-2">
                  <span className="label-xxsmall truncate text-gray-100">
                    {slot.show.stage.venue.shortNameWithoutRegion}
                  </span>
                  <span className="label-small truncate text-gray-10">
                    {slot.show.name}
                  </span>
                </div>

                <div className="grid gap-4">
                  <div className="flex items-center gap-6">
                    <UilCalendarAlt className="size-16 shrink-0 fill-gray-50" />
                    <div className="label-xsmall text-gray-10">
                      {formatSlotModel(slot, {
                        type: `start`,
                        formatStartDatetime: `MM/dd`,
                      })}
                    </div>
                  </div>

                  <div className="flex items-center gap-6">
                    <UilClockNine className="size-16 shrink-0 fill-gray-50" />
                    <span className="paragraph-small truncate text-gray-50">
                      {formatSlotModel(slot, {
                        type: `both`,
                        formatStartDatetime: `h:mm a`,
                        formatEndDatetime: `h:mm a zzz`,
                      })}
                    </span>
                  </div>

                  <div className="flex items-center gap-6">
                    <UilUsdCircle className="size-16 shrink-0 fill-gray-50" />
                    <span className="paragraph-small truncate text-gray-50">
                      {offerText}
                    </span>
                  </div>
                </div>

                {`ntRec` in slot && slot.ntRec && (
                  <div className="">
                    <ExpiringInBadge
                      expiresAt={parseISO(slot.ntRec.expiresAt)}
                    />
                  </div>
                )}
              </div>

              {artist && (
                <div className="flex items-center gap-8">
                  <ImageWithFallback
                    key={artist.photo1x1}
                    src={artist.photo1x1}
                    width={20}
                    height={20}
                    className="rounded-full"
                  />
                  <span className="label-xsmall">{artist.name}</span>
                </div>
              )}
            </div>
          </div>
        </InternalLink>
      );
    }
    case `grid`: {
      return (
        <InternalLink {...linkProps} className="contents">
          <div className="flex flex-col gap-16">
            <div className="relative aspect-square w-304 shrink-0">
              <ImageWithFallback
                key={SlotUtils.getSlotImage(slot)[0]}
                src={SlotUtils.getSlotImage(slot)[0]}
                sizes="100vw"
                layout="fill"
                className="shrink-0 rounded-12 object-cover"
              />
              <div className="absolute flex w-full items-start justify-end p-16">
                {`ntRec` in slot && slot.ntRec && (
                  <ExpiringInBadge expiresAt={parseISO(slot.ntRec.expiresAt)} />
                )}
              </div>
            </div>

            <div className="flex flex-col ">
              <span className="label-xxsmall truncate text-gray-100">
                {slot.show.stage.venue.shortNameWithoutRegion}
              </span>
              <div className="h-4" />
              <span className="label-small truncate text-gray-10">
                {slot.show.name}
              </span>
              <div className="h-8" />
              <div className="flex items-center gap-8">
                <UilCalendarAlt className="size-16 shrink-0 fill-gray-25" />
                <div className="label-xsmall text-gray-25">
                  {formatSlotModel(slot, {
                    type: `start`,
                    formatStartDatetime: `MM/dd`,
                  })}
                </div>
                <span className="paragraph-small truncate text-gray-25">
                  {formatSlotModel(slot, {
                    type: `both`,
                    formatStartDatetime: `h:mm a`,
                    formatEndDatetime: `h:mm a zzz`,
                  })}
                </span>
              </div>
              <div className="h-4" />
              <div className="flex items-center gap-8">
                <UilUsdCircle className="size-16 shrink-0 fill-gray-25" />
                <span className="paragraph-small truncate text-gray-25">
                  {offerText}
                </span>
              </div>
            </div>

            {artist && (
              <div className="flex items-center gap-8">
                <ImageWithFallback
                  key={artist.photo1x1}
                  src={artist.photo1x1}
                  width={24}
                  height={24}
                  className="rounded-full"
                />
                <span className="label-xsmall">{artist.name}</span>
              </div>
            )}
          </div>
        </InternalLink>
      );
    }
    default: {
      throw new Error(`Invalid variant: ${variant}`);
    }
  }
}

function PastSlotCard(props: {
  slot: MySlotFragment | SlotFragment;
  variant: 'list' | 'grid';
}) {
  const { slot, variant } = props;
  const isMySlot = `offer` in slot;
  const linkProps: InternalLinkProps = isMySlot
    ? {
        builder: {
          type: `slot-detail`,
          slotUuid: slot.uuid,
        },
      }
    : { href: null };

  const artist = slot.selectedArtist;

  switch (variant) {
    case `list`: {
      return (
        <InternalLink {...linkProps} className="contents">
          <div className="flex gap-16 overflow-hidden px-16">
            <div className="size-88 shrink-0">
              <ImageWithFallback
                key={SlotUtils.getSlotImage(slot)[0]}
                src={SlotUtils.getSlotImage(slot)[0]}
                width={88}
                height={88}
                className="rounded-12 object-cover"
              />
            </div>

            <div className="flex flex-col gap-16 overflow-hidden">
              <div className="flex flex-col gap-8 overflow-hidden">
                <div className="flex flex-col gap-4">
                  <span className="label-xxsmall truncate text-gray-100">
                    {slot.show.stage.venue.shortNameWithoutRegion}
                  </span>
                  <span className="label-small truncate text-gray-10">
                    {slot.show.name}
                  </span>
                </div>

                <div className="flex items-center gap-8">
                  <span className="paragraph-small truncate text-gray-25">
                    {formatSlotModel(slot, {
                      type: `start`,
                      formatStartDatetime: `MM/dd/yyyy`,
                    })}
                  </span>
                  {isMySlot && (
                    <span className="label-xsmall">
                      <PayoutBadge payoutSent={slot.payoutSent ?? false} />
                    </span>
                  )}
                </div>
              </div>

              {artist && (
                <div className="flex items-center gap-8">
                  <ImageWithFallback
                    key={artist.photo1x1}
                    src={artist.photo1x1}
                    width={20}
                    height={20}
                    className="rounded-full"
                  />
                  <span className="label-xsmall">{artist.name}</span>
                </div>
              )}
            </div>
          </div>
        </InternalLink>
      );
    }
    case `grid`: {
      return (
        <InternalLink {...linkProps}>
          <div className="flex flex-col gap-16">
            <div className="relative aspect-[322/200] w-full shrink-0 md:aspect-[304/240] md:w-304">
              <ImageWithFallback
                key={SlotUtils.getSlotImage(slot)[0]}
                src={SlotUtils.getSlotImage(slot)[0]}
                sizes="100vw"
                layout="fill"
                className="shrink-0 rounded-12 object-cover"
              />
            </div>

            <div className="flex flex-col gap-6 overflow-hidden">
              <div className="flex flex-col gap-4">
                <span className="label-xxsmall truncate text-gray-100">
                  {slot.show.stage.venue.shortNameWithoutRegion}
                </span>
                <span className="label-small truncate text-gray-10">
                  {slot.show.name}
                </span>
              </div>
              <div className="flex items-center gap-8">
                <span className="paragraph-small truncate text-gray-25">
                  {formatSlotModel(slot, {
                    type: `start`,
                    formatStartDatetime: `MM/dd/yyyy`,
                  })}
                </span>
                {isMySlot && (
                  <span className="label-xsmall">
                    <PayoutBadge payoutSent={slot.payoutSent ?? false} />
                  </span>
                )}
              </div>
            </div>
            {artist && (
              <div className="flex items-center gap-8">
                <ImageWithFallback
                  key={artist.photo1x1}
                  src={artist.photo1x1}
                  width={24}
                  height={24}
                  className="rounded-full"
                />
                <span className="label-xsmall">{artist.name}</span>
              </div>
            )}
          </div>
        </InternalLink>
      );
    }
    default: {
      throw new Error(`Invalid variant: ${variant}`);
    }
  }
}

function SlotCard(props: {
  slot: MySlotFragment | SlotFragment;
  type: SlotType;
  variant: 'list' | 'grid';
}) {
  const { slot, type, variant } = props;

  switch (type) {
    case SlotType.Upcoming: {
      return (
        <FutureSlotCard
          slot={slot}
          artist={slot.selectedArtist ?? undefined}
          variant={variant}
        />
      );
    }
    case SlotType.Recommended: {
      const artist =
        `ntRec` in slot
          ? slot.ntRec?.artist ?? slot.recommendation?.artist
          : undefined;

      return <FutureSlotCard slot={slot} artist={artist} variant={variant} />;
    }
    case SlotType.Past: {
      return <PastSlotCard slot={slot} variant={variant} />;
    }
    default: {
      throw new Error(`Invalid slot type: ${type}`);
    }
  }
}

export default SlotCard;
